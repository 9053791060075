import { Box, Stack } from "@mui/material";
import Hls from "hls.js";
import { useEffect, useRef, useState } from "react";
import { PlayIcon } from "ui";
import { Layout } from "../shared/Layout";

type VideoProps = {
  simulated?: boolean;
  src: string;
  buttonLabel: string;
  disabled?: boolean;
  onContinue: () => void;
  loading?: boolean;
  description?: string;
  settings?: {
    auto_proceed_timer: number;
    countdown_timer: number;
    enable_auto_play: boolean;
    enable_watch_to_proceed: boolean;
  };
};

export const Video = ({
  src,
  buttonLabel = "Next",
  disabled,
  onContinue,
  loading,
  description,
  settings = {
    auto_proceed_timer: 0,
    countdown_timer: 0,
    enable_auto_play: false,
    enable_watch_to_proceed: true,
  },
}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [watchedTime, setWatchedTime] = useState(0);
  const [hasBeenFullyWatched, setHasBeenFullyWatched] = useState(false);
  const isWatchedCompletely = watchedTime === videoRef.current?.duration;

  const handleTimeUpdate = () => {
    const video = videoRef?.current;
    if (!video) return;

    if (isWatchedCompletely) {
      setHasBeenFullyWatched(true);
      return;
    }

    setWatchedTime((prev) => {
      const isSeeking = video.currentTime - prev > 1;
      return isSeeking ? prev : video.currentTime;
    });
  };

  useEffect(() => {
    if (videoRef.current && src) {
      if (!Hls.isSupported()) {
        (videoRef.current as HTMLVideoElement).src = src;
        return;
      }

      let hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
    }
  }, [src]);

  const handlePlayPause = () => {
    if (!videoRef.current) return;

    isPlaying
      ? (videoRef.current as HTMLVideoElement).pause()
      : (videoRef.current as HTMLVideoElement).play();

    setIsPlaying(!isPlaying);
  };

  const handleVideoPlay = () => {
    if (!settings.enable_auto_play) {
      setIsPlaying(true);
      setIsPlayed(true);
    }
  };

  const handleVideoPause = () => {
    if (!settings.enable_auto_play) {
      setIsPlaying(false);
    }
  };

  const handleVideoClick = () => {
    if (!isPlayed && !settings.enable_auto_play) {
      setIsPlayed(true);
    }
  };

  const hidePlayButton = navigator?.userAgent?.search("Firefox") > -1;

  return (
    <Layout
      buttonLabel={buttonLabel}
      disabled={
        disabled ||
        (!isWatchedCompletely &&
          settings.enable_watch_to_proceed &&
          !hasBeenFullyWatched)
      }
      onContinue={onContinue}
      loading={loading}
      start_countdown={isPlayed || settings.enable_auto_play}
      auto_proceed_timer={settings.auto_proceed_timer}
      countdown_timer={settings.countdown_timer}
    >
      <Stack spacing="24px" sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            overflow: "hidden",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: { xs: "0px", sm: "6px" },
            video: {
              width: "100%",
              cursor: "pointer",
              // Add these styles to hide the seek bar
              "&::-webkit-media-controls-timeline": {
                display: "none !important",
              },
              "&::-webkit-media-controls-progress-bar": {
                display: "none !important",
              },
              // For Firefox
              "&::-moz-range-track": {
                display: "none !important",
              },
              "&::-moz-range-thumb": {
                display: "none !important",
              },
            },
          }}
        >
          <video
            ref={videoRef}
            playsInline
            muted={settings.enable_auto_play}
            controls
            className="custom-video-controls"
            autoPlay={settings.enable_auto_play}
            disablePictureInPicture
            controlsList="nodownload noplaybackrate"
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onClick={handleVideoClick}
            onTimeUpdate={handleTimeUpdate}
            onSeeked={() => setWatchedTime(0)} // Fires when seeking ends
          />
          {!hidePlayButton && !isPlaying && !settings.enable_auto_play && (
            <Box
              sx={{
                zIndex: 1,
                top: "50%",
                left: "50%",
                width: "60px",
                height: "60px",
                display: "flex",
                cursor: "pointer",
                fontSize: "2.5rem",
                borderRadius: "50%",
                position: "absolute",
                alignItems: "center",
                pointerEvents: "none",
                justifyContent: "center",
                transition: "opacity 0.3s ease",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(27, 30, 61, 0.5)",
              }}
              id="play-button"
              onClick={handlePlayPause}
            >
              <PlayIcon w={40} h={40} fill="#fff" />
            </Box>
          )}
        </Box>
        {description && (
          <Box
            dangerouslySetInnerHTML={{ __html: description }}
            sx={{
              py: {
                xs: "24px",
                sm: "0px",
              },
              px: { xs: "24px", sm: "0" },
            }}
          />
        )}
      </Stack>
    </Layout>
  );
};
