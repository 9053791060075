import { useState } from "react";
import { DragInfo, DragItemProps, RankingProps } from "./types";
import { DraggableList, RankIcon } from "ui";
import { rankItem, unrankItem, updateItemRank, updateOtherItem } from "./utils";
import { rankingStyles } from "./styles";

const Ranking: React.FC<RankingProps> = ({
  choices,
  loading,
  handleChange,
  type,
}) => {
  const [items, setItems] = useState<DragItemProps[]>(() =>
    choices.map((choice, index) => ({
      id: choice.id,
      content: choice.label,
      is_open_ended: choice.open_ended,
      originalRank: index + 1,
    })),
  );
  const rankedItemsCount = items.filter((item) => item.ranked).length;

  const updateRanking = (result: DragItemProps[]) => {
    const allItemsRanked = result.every((item) => item.ranked);

    setItems(result);
    handleChange(
      allItemsRanked
        ? result.map((item) => ({
            id: item.id,
            label: item.content as string,
            ranking_position: item.rank as number,
            is_open_ended: item?.open_ended || false,
          }))
        : null,
    );
  };

  const handleReorder = (newItems: DragItemProps[], dragInfo: DragInfo) => {
    const result = newItems.map((newItem, index) => {
      const existingItem = items.find((i) => i.id === newItem.id);
      if (!existingItem) return newItem;
      const isDraggedItem = newItem.id === dragInfo.draggedItem.id;

      return isDraggedItem
        ? updateItemRank(existingItem, dragInfo.newIndex + 1)
        : updateOtherItem(existingItem, index, dragInfo.newIndex);
    });

    updateRanking(result);
  };

  const handleItemClick = (id: string) => {
    const item = items.find((item) => item.id === id);
    if (!item) return;

    const newItems = item.ranked
      ? unrankItem(items, item)
      : rankItem(items, id, rankedItemsCount);

    updateRanking(newItems);
  };

  return (
    <DraggableList
      items={items.map((item) => ({
        ...item,
        content: <RankItem item={item} rankedItemsCount={rankedItemsCount} />,
      }))}
      onReorder={handleReorder}
      itemStyle={rankingStyles}
      onItemClick={handleItemClick}
    />
  );
};

const RankItem: React.FC<{ item: DragItemProps; rankedItemsCount: number }> = ({
  item,
  rankedItemsCount,
}) => {
  return (
    <div
      key={item.id}
      className={`item-wrapper ${item.ranked ? "ranked" : ""}`}
    >
      <div className="rank-indicator">
        <div className="rank-number">
          {item.ranked ? item.rank : rankedItemsCount + 1}
        </div>
        {!item.ranked && <RankIcon w={20} h={20} />}
      </div>
      <div className="content">{item.content}</div>
    </div>
  );
};

export default Ranking;
