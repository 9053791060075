"use client";
import { useAppContext } from "@/src/contexts";
import { EmptyLoader } from "@src/components/Loader";
import { PropsWithChildren, Suspense } from "react";
import { Footer, Header } from "steps";

export const ContentWrapper = ({ children }: PropsWithChildren) => {
  const { showHeader, hasProducts, showCart, setShoppingCartOpen, testMode } =
    useAppContext();

  return (
    <div className="main" style={{ height: showHeader ? 'calc(100dvh - 48px)' : '100dvh' }}>
      {showHeader && <Header
        showCart={showCart}
        testMode={testMode}
        hasProducts={hasProducts}
        onCartClick={() => setShoppingCartOpen((p) => !p)}
      />}
      <Suspense fallback={<EmptyLoader />}>{children}</Suspense>
      <Footer />
    </div>
  );
};
