import { DragItemProps } from "./types";

// Generic array manipulation
export function moveItemById<T extends { id: string }>(
  array: T[],
  itemId: string,
  toIndex: number,
): T[] {
  const newArray = [...array];
  const fromIndex = newArray.findIndex((item) => item.id === itemId);

  if (fromIndex === -1) return newArray;

  const [item] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, item);

  return newArray;
}

// Item ranking operations
export const updateItemRank = (
  item: DragItemProps,
  rank: number,
  ranked: boolean = true,
): DragItemProps => ({
  ...item,
  rank: ranked ? rank : undefined,
  ranked,
});

export const rankItem = (
  items: DragItemProps[],
  id: string,
  rankedItemsCount: number,
): DragItemProps[] => {
  const newItems = moveItemById([...items], id, rankedItemsCount);
  const shouldRankLastItem =
    newItems.filter((item) => item.ranked).length + 2 === items.length;

  return newItems.map((item, index) =>
    item.id === id
      ? updateItemRank(item, rankedItemsCount + 1)
      : shouldRankLastItem && index === newItems.length - 1
        ? updateItemRank(item, items.length)
        : item,
  );
};

export const unrankItem = (
  items: DragItemProps[],
  itemToUnrank: DragItemProps,
): DragItemProps[] => {
  // Get ranked items excluding the one being unranked
  const rankedItems = items
    .filter((item) => item.ranked && item.id !== itemToUnrank.id)
    .map((item, index) => ({ ...item, rank: index + 1 }));

  // Create unranked item with reset ranking
  const unrankedItem = updateItemRank(itemToUnrank, 0, false);

  // Get other unranked items
  const otherUnrankedItems = items.filter((item) => !item.ranked);

  // Sort unranked items by their original rank
  const sortedUnrankedItems = [...otherUnrankedItems, unrankedItem].sort(
    (a, b) => (a.originalRank ?? 0) - (b.originalRank ?? 0),
  );

  return [...rankedItems, ...sortedUnrankedItems];
};

export const updateOtherItem = (
  item: DragItemProps,
  index: number,
  dragNewIndex: number,
): DragItemProps => ({
  ...item,
  ranked: index < dragNewIndex ? true : item.ranked,
  rank: index + 1,
});
