import { Box, TextField, Typography } from "@mui/material";
import {
  DatePicker,
  DateType,
  EmailIcon,
  Field,
  NumberField,
  PhoneIcon,
} from "ui";
import { useEffect, useMemo, useRef, useState } from "react";
import { parse } from "date-fns";
import { Layout } from "../shared/Layout";

type OpenAnswerProps = {
  simulated?: boolean;
  title: string;
  loading: boolean;
  disabled?: boolean;
  buttonLabel?: string;
  settings: {
    answer_format?:
    | "text"
    | "number"
    | "currency"
    | "date"
    | "email"
    | "phone_number";
    enable_answer_format?: boolean;
    enable_require_response?: boolean;
    min?: number | string;
    max?: number | string;
  };
  onContinue: (answer: string) => void;
  inputPlaceholderLabel?: string;
};

export const OpenAnswer = ({
  title,
  settings,
  onContinue,
  buttonLabel = "Continue",
  loading,
  disabled,
  inputPlaceholderLabel,
}: OpenAnswerProps) => {
  const [answer, setAnswer] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);

  const {
    answer_format: type,
    enable_answer_format,
    enable_require_response,
    max,
    min,
  } = settings || {};

  const questionType = useMemo(() => {
    if (enable_answer_format) {
      return type;
    }
    return "text";
  }, [enable_answer_format, type]);

  const isDisabled = useMemo(() => {
    const value =
      questionType === "text" ? answer?.trim()?.length : Number(answer);
    const isCheckable = enable_answer_format && questionType !== "date";

    if (
      questionType &&
      enable_answer_format &&
      (enable_require_response || answer) &&
      ["email", "phone_number"].includes(questionType)
    ) {
      if (questionType === "phone_number") {
        const cleanedNumber = answer.replace(/[\s()-]/g, "");
        setAnswer(cleanedNumber);
      }

      const regex: {
        [key: string]: RegExp;
      } = {
        email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        phone_number:
          /^[\+]?[(]?[0-9]{3}[)]?[\s.-]?[0-9]{3}[\s.-]?[0-9]{4,6}$/im,
      };
      if (regex[questionType] && !regex[questionType].test(answer)) {
        return true;
      }
    }

    if (answer?.trim()?.length === 0 && !enable_require_response) {
      return false;
    }

    if (
      isCheckable &&
      ((max && value > Number(max)) || (min && value < Number(min)))
    ) {
      return true;
    }

    if (enable_answer_format && (min || max) && questionType === "date") {
      const answerDate = parseDate(answer);
      const validMin = parseDate(min as string);
      const validMax = parseDate(max as string);

      if (
        !answer ||
        (validMin && answerDate < validMin) ||
        (validMax && answerDate > validMax)
      ) {
        return true;
      }
    }
    if (enable_require_response) {
      return !answer?.trim();
    }

    return false;
  }, [
    enable_require_response,
    questionType,
    answer,
    min,
    max,
    enable_answer_format,
  ]);

  useEffect(() => {
    return () => {
      // cleanup
      setAnswer("");
    };
  }, [settings]);

  const containerRef = useRef<HTMLDivElement>(null);
  const [maxRows, setMaxRows] = useState<number>(16);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const resizeHandler = () => {
      if (containerRef.current) {
        const { height, width } = containerRef.current.getBoundingClientRect();
        const maxRowsValue = Math.round(height / 26);

        setMaxRows(width < 600 ? maxRowsValue : 16);
      }
    };

    resizeHandler();

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);


  const validateEmail = (value: string) => {
    const regex: {
      [key: string]: RegExp;
    } = {
      email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    };
    if (regex.email && !regex.email.test(value)) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError(null);
    }
  };

  return (
    <Layout
      title={title}
      onContinue={() => onContinue(answer)}
      disabled={isDisabled || disabled}
      loading={loading}
      buttonLabel={buttonLabel}
    >
      <Box
        ref={containerRef}
        sx={{
          width: "100%",
          height: "100%",
          pt: {
            xs: "24px",
            sm: "40px",
          },
          pb: {
            xs: "24px",
            sm: "24px",
          },
          px: {
            xs: "24px",
            sm: "0px",
          },
        }}
      >
        {questionType === "text" && (
          <TextField
            multiline
            sx={{
              width: "100%",
            }}
            rows={maxRows}
            placeholder={inputPlaceholderLabel || "Please provide an answer"}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        )}
        {questionType === "number" && (
          <NumberField
            clearable
            avoidDecimals
            defaultValue={answer.trim() === "" ? null : Number(answer)}
            onChange={(value) =>
              setAnswer(value === null ? "" : value.toString())
            }
            placeholder={inputPlaceholderLabel || "Please provide a number"}
          />
        )}
        {questionType === "currency" && (
          <NumberField
            clearable
            defaultValue={answer.trim() === "" ? null : Number(answer)}
            onChange={(value) =>
              setAnswer(value === null ? "" : value.toString())
            }
            placeholder={inputPlaceholderLabel || "Please provide a price"}
            icon={<Box>$</Box>}
          />
        )}
        {questionType === "date" && (
          <DatePicker
            defaultValue={getFormattedDate(answer)}
            calendarProps={{
              minimumDate: getFormattedDate(min as string),
              maximumDate: getFormattedDate(max as string),
            }}
            onChange={(value) => setAnswer(formatDate(value))}
          />
        )}
        {questionType === "email" && (
          <>
            <Field
              type="email"
              sx={{
                width: "100%",
                position: "relative",
                paddingLeft: "50px",
                overflow: "hidden",
              }}
              placeholder="example@email.com"
              value={answer}
              component={{
                position: "left",
                render: <EmailIcon color="var(--mui-palette-input_fields-text-color)" />,
                styles: {
                  width: "38px",
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  left: 2,
                  borderRadius: "8px 0px 0px 8px",
                  borderRight: "1px solid var(--mui-palette-layout-border-color)",
                },
              }}
              onChange={(val) => setAnswer(val)}
              onBlur={(value) => validateEmail(value)}
            />
            {emailError && <Typography color="error">{emailError}</Typography>}</>
        )}
        {questionType === "phone_number" && (
          <Field
            type="tel"
            sx={{
              width: "100%",
              position: "relative",
              paddingLeft: "50px",
              overflow: "hidden",
            }}
            placeholder="+123 456 7890"
            value={answer}
            component={{
              position: "left",
              render: <PhoneIcon color="var(--mui-palette-input_fields-text-color)" />,
              styles: {
                width: "38px",
                height: "38px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                left: 2,
                borderRadius: "8px 0px 0px 8px",
                borderRight: "1px solid var(--mui-palette-layout-border-color)",
              },
            }}
            onChange={(val) => setAnswer(val)}
          />
        )}
      </Box>
    </Layout>
  );
};

const parseDate = (date: string) => {
  const dateObj = parse(date, "dd-MM-yyyy", new Date());
  return dateObj;
};

const getFormattedDate = (date: string) => {
  if (!date) return undefined;

  const [day, month, year] = date.split("-");

  return {
    year: Number(year),
    month: Number(month),
    day: Number(day),
  };
};

const formatDate = (date: DateType) => {
  if (!date) return "";

  const { day, month, year } = date;

  return `${day}-${month}-${year}`;
};
