export const rankingStyles = {
  // Container
  margin: "8px 0",
  display: "flex",
  overflow: "hidden",
  borderRadius: "6px",
  alignItems: "center",
  //   border: "solid 1px #ebebf0",
  //   background: "var(--mui-palette-options-border-color)",

  // Main content wrapper
  ".drag-item-content-wrapper": {
    width: "100%",

    // Item wrapper (ranked/unranked container)
    ".item-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 2,
      padding: "8.5px 12px",
      border: "solid 1px #ebebf0",
      borderRadius: "var(--mui-palette-options-corners-rounding)",

      color: "var(--mui-palette-options-text-color)",
      "&:hover": {
        border: "solid 1px var(--mui-palette-options-active-border-color)",
        transition: "border 0.3s ease-in-out",
        backgroundColor:
          "var(--mui-palette-options-light-active-background-color)",
      },

      // Ranked state
      "&.ranked": {
        background: "var(--mui-palette-options-active-background-color)",
        border: "solid 1px var(--mui-palette-options-active-border-color)",
      },

      // Left side - rank indicator
      ".rank-indicator": {
        display: "flex",
        alignItems: "center",
        color: "white",
        fontWeight: "bold",
        fontSize: "12px",

        ".rank-number": {
          width: 20,
          height: 20,
          display: "none",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "3px",
        },
      },

      // Right side - content
      ".content": {
        margin: 0,
        padding: 0,
      },

      // States for ranked items
      "&.ranked .rank-number": {
        display: "flex",
        background: "var(--mui-palette-options-active-color)",
      },

      // For desktop devices only
      "@media (hover: hover)": {
        // Hover states for unranked items
        "&:not(.ranked):hover": {
          ".rank-number": {
            display: "flex",
            background: "var(--mui-palette-options-hover-default-color)",
          },
          svg: {
            //   RankIcon
            display: "none",
          },
        },
      },
    },
    "&:not(.ranked):active": {
      ".rank-number": {
        display: "none",
      },
    },
  },
};
